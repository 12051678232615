import React, { useState } from 'react';
import { collection, addDoc } from "firebase/firestore";
import { db } from '../firebase';
import { useNavigate } from 'react-router-dom';
import { RENDER_URL } from '../common/Constants'

function AdminContainer() {

    const _histroy = useNavigate();

    const [formData, setFormData] = useState({
        title: '',
        price: '',
        offer: '',
        deal: '',
        description: '',
        category: '',
        image: '',
        rating: '',
        ratingCount: '',
        url1: '',
        url2: '',
        url3: '',
    });


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    const styles = {
        form: {
            display: 'flex',
            flexDirection: 'column',
            maxWidth: '400px',
            margin: '0 auto',
            padding: '20px',
            border: '1px solid #ccc',
            borderRadius: '4px',
        },
        label: {
            margin: '10px 0',
        },
        input: {
            padding: '5px 10px',
            borderRadius: '4px',
            border: '1px solid #ccc',
            marginBottom: '10px',
            width: '100%',
        },
        button: {
            backgroundColor: '#4CAF50',
            color: 'white',
            padding: '10px 20px',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer',
        },
    };


    const handleSubmit = (e) => {
        e.preventDefault();

        // Basic validation - check if required fields are not empty
        if (!formData.title || !formData.price || !formData.description || !formData.image) {
            alert('Please fill out all required fields.');
            return;
        }


        const item = {
            "id": 1212,
            "title": formData.title,
            "price": formData.price,
            "offer": formData.offer,
            "deal": formData.deal,
            "description": formData.description,
            "category": formData.category,
            "image": formData.image,
            "rating": {
                "rate": formData.rating,
                "count": formData.ratingCount
            },
            "platforms": {
                "amazon": formData.url1,
                "flipkart": formData.url2,
                "myntra": formData.url3
            },
            "date": new Date().getMilliseconds()
        }

        addItem(item);
    };


    const addItem = async (item) => {

        try {
            const docRef = await addDoc(collection(db, "products"), {
                item
            });
            console.log("Document written with ID: ", docRef.id);
        } catch (e) {
            console.error("Error adding document: ", e);
        }

    }

    const goToDashboard = () => {

        _histroy(RENDER_URL.LOGIN_URL, { state: { isAdmin: true } })

    }


    const addMoreData = async () => {

        let item = {
            "Amazon": [
                {
                    "id": "B0CD7ZH33X",
                    "title": "CUISINART 6 Blades Juicer Rechargeable Portable Electric USB Juicer Bottle Blender for Making Juice,Travel Juicer for Fruits and Vegetables,Juice Maker Machine (Multicolour) (Small)",
                    "price": "₹499.00",
                    "offer": 2199,
                    "deal": "prime deal",
                    "description": [
                        " <b>Portable &amp; Convenient to Charge</b>: This little body light weight design is easy for you carry it to school,office,parks,camping, anywhere you want to. and it also convenient for charge by power bank, laptop, computer, car or other usb devices.  ",
                        " <b>Rechargeable&amp;Portable </b>- The USB juicer cup comes equipped with a built-in 2000mAh rechargeable battery that can be easily charged by power bank, laptop, computer, mobile phones or other USB devices .  ",
                        " <b>Easy and Safe Cleaning </b>- It has juicer mixer have smart safety protection device, magnetic sensing switch ultra safe to use and clean, the juicer cup's body and bottom can be separated, you can easy to clean it  ",
                        " <b></b>Multi-function - With our juice blender mixing kinds of fruit and vegetables,you can make natural tasty juice,milkshake,smoothie and other baby food.It's very portable and great for traveling, outdoor.; Multi Functional : drink a protein shake, smoothie or healthy drink everyday, not only for making a protein shake, smoothie, juice, and it works well for vegetable and other baby food.  ",
                        " <b></b>Material Type: Plastic; Included Components: Mixers  "
                    ],
                    "category": "",
                    "image": [
                        "https://m.media-amazon.com/images/I/61LrJujGJSL._SX466_.jpg"
                    ],
                    "rating": {
                        "rate": 5,
                        "count": 1000
                    },
                    "platforms": {
                        "amazon": "https://amzn.to/3qu9pWS"
                    },
                    "date": 676
                },
                {
                    "id": "B07BGY99SN",
                    "title": "HGMZZQ 60/40 Tin Lead Solder Wire with Rosin for Electrical Soldering 0.039 inch(1.0mm-0.22lbs)",
                    "price": "₹854.05",
                    "offer": 1499,
                    "deal": "prime deal",
                    "description": [
                        " 1.Tin Lead Solder Wire: High Quality Rosin Core Solder Wire  ",
                        " 2.Sn/pb: 60%/40%; Flux: 2.2%;Diameter 1.0 mm/ 0.039 inch  ",
                        " 3.Lower Melting Point: 361F/183C. easy to be soldered, flows smoothly, soldering fast and strong soldering point.  ",
                        " 4.Pretty good flow, melts evenly and clean,less smoke,Solder joints reliable and beautiful  ",
                        " 5.The applicable scope: household appliances, stereos,headset,toys, circuit board maintenance etc.  "
                    ],
                    "category": "",
                    "image": [
                        "https://m.media-amazon.com/images/I/716jcbVoqKL._SY450_.jpg"
                    ],
                    "rating": {
                        "rate": 5,
                        "count": 1000
                    },
                    "platforms": {
                        "amazon": "https://amzn.to/3saQ4dM"
                    },
                    "date": 760
                },
                {
                    "id": "B083VQSQH4",
                    "title": "Chitra Zinc Duck Wall to Wall Concealed 1 Inch Dia Curtain Rod Modern Bracket (Antique Brass, Brown) - Pack of 4",
                    "price": "₹409.00",
                    "offer": 420,
                    "deal": "prime deal",
                    "description": [
                        " Material: Premium Zinc Alloy,Finish: Antique Brass and Installation Screws will be provided with Product&lt;br&gt;&lt;br&gt;  ",
                        " Suitable for 1 Inch Curtain Rod and Pocket Style Curtains&lt;br&gt;&lt;br&gt;  ",
                        " Duck Zinc Concealed Bracket are of the Finniest quality which can be found; Durable,Attractive Look And Modern Design&lt;br&gt;&lt;br&gt;  ",
                        " Safety Warning : Clean with a soft and dry cloth to protect finish, avoid the use of household cleaners or abrasives.&lt;br&gt;&lt;br&gt;  ",
                        " Color Name: Brown&lt;br&gt;  "
                    ],
                    "category": "",
                    "image": [
                        "https://m.media-amazon.com/images/I/61oNsdTCRSL._SX466_.jpg"
                    ],
                    "rating": {
                        "rate": 5,
                        "count": 1000
                    },
                    "platforms": {
                        "amazon": "https://amzn.to/44oG6Df"
                    },
                    "date": 221
                },
                {
                    "id": "B08P2P7BQK",
                    "title": "Copper Vahan Durghatna Nashak Yantra(Accident Saver Yantra) for Worship, Devotion and Meditation (3 inches)",
                    "price": "₹500.00",
                    "offer": 950,
                    "deal": "prime deal",
                    "description": [
                        " Vahan means “vehicle”, Durghatna means “accident” and Nashak means “remove”.  ",
                        " Thick copper plate is used for durability.  ",
                        " Copper is well known for its grasping capacity.  "
                    ],
                    "category": "",
                    "image": [
                        "https://m.media-amazon.com/images/I/41FkuMe-OSL.jpg"
                    ],
                    "rating": {
                        "rate": 5,
                        "count": 1000
                    },
                    "platforms": {
                        "amazon": "https://amzn.to/3DTwvcH"
                    },
                    "date": 223
                },
                {
                    "id": "B01MDQVTBY",
                    "title": "Gray : Simple Designs Home LF1014-GRY Floor Lamp Organizer Storage Shelf with Linen Shade, Gray",
                    "price": "₹13,999.00",
                    "offer": 19999,
                    "deal": "prime deal",
                    "description": [
                        " Storage  "
                    ],
                    "category": "",
                    "image": [
                        "https://m.media-amazon.com/images/I/61-OGEBGp1L._SX466_.jpg"
                    ],
                    "rating": {
                        "rate": 5,
                        "count": 1000
                    },
                    "platforms": {
                        "amazon": "https://amzn.to/446KRRG"
                    },
                    "date": 84
                },
                {
                    "id": "B098B5J5M1",
                    "title": "Chitra Brass Icon Wall to Wall Concealed Bracket for 1 Inch Dia Curtain Rod (Antique Gold) - Pack of 4",
                    "price": "₹499.00",
                    "offer": 532,
                    "deal": "prime deal",
                    "description": [
                        " Material: Brass, Finish: Antique Gold and Installation Screws will be provided with Product  ",
                        " Suitable for 1 Inch Curtain Rod and Pocket Style Curtains  ",
                        " Icon Brass Concealed Bracket are of the Finniest quality which can be found  ",
                        " Durable,Attractive Look And Modern Design  ",
                        " Safety Warning : Clean with a soft and dry cloth to protect finish, avoid the use of household cleaners or abrasives.  "
                    ],
                    "category": "",
                    "image": [
                        "https://m.media-amazon.com/images/I/51K8ZUqST3S.jpg"
                    ],
                    "rating": {
                        "rate": 5,
                        "count": 1000
                    },
                    "platforms": {
                        "amazon": "https://amzn.to/3qwxNHn"
                    },
                    "date": 250
                },
                {
                    "id": "B098B5CQHR",
                    "title": "Chitra Zinc Pasco End Cap for 1 Inch Dia Curtain Rod Pack of 4 (Silver)",
                    "price": "₹449.00",
                    "offer": 500,
                    "deal": "prime deal",
                    "description": [
                        " Material: Premium Zinc Alloy, Finish: Satin Silver and Installation Screws will be provided with Product  ",
                        " Suitable for 1 Inch Curtain Rod and Pocket Style Curtains  ",
                        " End Cap are of the Finniest quality which can be found  ",
                        " Durable,Attractive Look And Modern Design  ",
                        " Safety Warning : Clean with a soft and dry cloth to protect finish, avoid the use of household cleaners or abrasives.  "
                    ],
                    "category": "",
                    "image": [
                        "https://m.media-amazon.com/images/I/81ez67qpLyS._SX466_.jpg"
                    ],
                    "rating": {
                        "rate": 5,
                        "count": 1000
                    },
                    "platforms": {
                        "amazon": "https://amzn.to/447Va7Y"
                    },
                    "date": 53
                },
                {
                    "id": "B09ZPL2NY7",
                    "title": "Smart Interactive Prayer (Mat Adult Version)",
                    "price": "Prayer mat, Soft and Compact Polyester ABS Prayer mat for Home use for Children's Prayer",
                    "offer": "",
                    "deal": "prime deal",
                    "description": [
                        " There are 25 touch-sensitive keys  ",
                        " Quran Recitation is from a Qualified (Qari) with Ijazah.  ",
                        " Pray any salah with a touch of a button – simply select the prayer you wish to pray, once you have selected the prayer, this will only start when you stand on the feet position.  ",
                        " The whole prayer is guided by lighting up the different positions in prayer at the right time. Showing the person praying the position they need to go to at that particular time.  ",
                        " The prayer mat is also guided by touch-sensitive technology, for example, what you say in sujood will not start until the person praying touches that part of the prayer mat with their head. When they touch the right position, the mat recognises they are in sujood and then it will recite the sujood dua.  ",
                        " Learn Surahs, Adhan, Salah, Duas and much more  ",
                        " It is made from waterproof and fire-resistant materials.  "
                    ],
                    "category": "",
                    "image": [
                        "https://m.media-amazon.com/images/I/911MzgRUnFL._SX466_.jpg"
                    ],
                    "rating": {
                        "rate": 5,
                        "count": 1000
                    },
                    "platforms": {
                        "amazon": "https://amzn.to/3QFX0tO"
                    },
                    "date": 634
                },
                {
                    "id": "B08F2T2WDM",
                    "title": "DIVERGE FURNISHINGS Polyester Blend 2 Piece Door Curtains | 7 Feet Printed Polyester Blend Curtain for Bedroom | Eyelet Grommet Room Darkening Drapes for Kitchen &amp; Hall | Polyester Blend Parda | 7Ft, Brown",
                    "price": "₹749.00",
                    "offer": "",
                    "deal": "prime deal",
                    "description": [
                        " Product Dimensions : Door Curtain - 2 Piece - 7 x 4 Feet, 85 x 48 Inches or 217 x 122 Cm  ",
                        " Material Quality:- 100% High-Quality Velvet Which Has A Natural Resistance To Shrinkage And Wrinkles Making Them Easy To Maintain. It Gives A Much-Desired Sassy Look.Readymade Curtains Ideal For Living Room Or Bedroom.Vibrant Designing in Rich and Sharp Colors  ",
                        " Light Filtering:- These Curtains Are The Solution If You Feel Daylight Negatively Affect Your Sleep Or Rest, Are Sensitive To Light, Or Work Odd Hours As They Can Block Light Of The Room. They Also Ensure Total Privacy For You And Your Loved Ones.  ",
                        " Energy Smart Protection:- Light-Filtering Soft Fabric Balances Room Temperature By Insulating Against Summer Heat And Winter Chill. Protect Your Furniture, Floors And Artwork From The Ravages Of The Sun, The Drapes Will Pay For Themselves Off Over Time.Beautiful for Your Home and for Gifting.This Room Darkenninf curtain can block out up to 70% of light. Thus illuminates the room.  ",
                        " Package Content- 2 Panels Curtains with Expert Workmanship with 8 Eyelets for Easy Hanging On Top  ",
                        " Wash Care:- Normal Hand Wash Or Machine Wash [Light Colors To Be Wash Separately]  "
                    ],
                    "category": "",
                    "image": [
                        "https://m.media-amazon.com/images/I/71v6F6y2RSS._SX466_.jpg"
                    ],
                    "rating": {
                        "rate": 5,
                        "count": 1000
                    },
                    "platforms": {
                        "amazon": "https://amzn.to/44aKwgT"
                    },
                    "date": 902
                },
                {
                    "id": "B09RDWWMKC",
                    "title": "GLOXY ENTERPRISE Aluminium Antique Finish Curtain Bracket Parda Holder with Support 1 Inch Rod Pocket Finials Designer Door and Window Rod Support Fittings, Curtain Rod Holder (Pair of 1)",
                    "price": "₹379.05",
                    "offer": 1198,
                    "deal": "prime deal",
                    "description": [
                        " <b></b>Package Contains:- 1 Pair Rajwada Hathi Curtain Bracket, 1 Pair Rajwada Curtain Rod Support, 4 Pcs x Screw &amp; PVC Wall Grip  ",
                        " <b></b>Why GLOXY ENTERPRISE curtain accessories always come with unique and luxury designs of the best quality for valued customers. As well as its weight is kept low to make it easy to use without compromising on architectural and prehistoric appearance.  ",
                        " <b>MATERIAL </b>: Our curtain holder brackets are made from premium quality of aluminium and go through many processes to make it superior and ancient.  ",
                        " <b></b>Here we presented our curtain accessories with perfect required combination of curtain rod bracket, curtain holders and curtain rod end cap or wall to wall brackets. Also all pack comes with required screw and wall grip so you don't need to go in local market.  ",
                        " <b>CARE INSTRUCTIONS</b>: Avoid hard cleaners or abrasives for cleaning always use a soft cloth for cleaning  ",
                        " <b>SUITABLE FOR </b>: Our curtain support is best suited with 25mm / 1 Inch curtain pipe only.  "
                    ],
                    "category": "",
                    "image": [
                        "https://m.media-amazon.com/images/I/419sBnkFSwL._SX466_.jpg"
                    ],
                    "rating": {
                        "rate": 5,
                        "count": 1000
                    },
                    "platforms": {
                        "amazon": "https://amzn.to/45pqTT8"
                    },
                    "date": 860
                },
                {
                    "id": "B0B6T42TCZ",
                    "title": "Brown Scapular Catholic Premium Handmade in India with 100% Wool - Our Lady of Mt Carmel Escapulario (Premium Stitch Edges) Set of 2",
                    "price": "₹299.00",
                    "offer": 1499,
                    "deal": "prime deal",
                    "description": [
                        " Our Lady of Mount Carmel 100% Real Wool  ",
                        " Brown Scapular Escapulario Catolico  ",
                        " Premium Quality Hand Made in India  ",
                        " Comfortable to wear every day, not scratchy!  "
                    ],
                    "category": "",
                    "image": [
                        "https://m.media-amazon.com/images/I/61Fk4l9eq7L._SX466_.jpg"
                    ],
                    "rating": {
                        "rate": 5,
                        "count": 1000
                    },
                    "platforms": {
                        "amazon": "https://amzn.to/447SAPs"
                    },
                    "date": 735
                },
                {
                    "id": "B09B7SMDC5",
                    "title": "Hopdezyo Italian Gift Floor Lamp with Home Decor Black Cotton Fabric Drum Shade, Bulb, Wiring, E27 Holder Included, Pack of 1",
                    "price": "₹1,090.00",
                    "offer": 2250,
                    "deal": "prime deal",
                    "description": [
                        " Black Cotton Fabric Drum Shade, Size : 11 x 12 Inch  ",
                        " Ideal for decorating homes and gifting purposes  ",
                        " Adjustable Lighting  ",
                        " Handmade in India  ",
                        " Made with high-quality material  "
                    ],
                    "category": "",
                    "image": [
                        "https://m.media-amazon.com/images/I/61sVPzc+VYL._SX466_.jpg"
                    ],
                    "rating": {
                        "rate": 5,
                        "count": 1000
                    },
                    "platforms": {
                        "amazon": "https://amzn.to/3KFfdnw"
                    },
                    "date": 691
                },
                {
                    "id": "B0B75P7N6X",
                    "title": "Onesto® Mini Rope Storage Natural Handwoven Jute Shelf Basket For Your Home &amp; Kitchen (Small,Medium,Large) - Pack of 3 (SET OF 3, White)",
                    "price": "₹449.00",
                    "offer": 1299,
                    "deal": "prime deal",
                    "description": [
                        " It is made of natural and environment-friendly jute and cotton rope making it sustainable, lightweight and portable.  ",
                        " Spacious enough to be used as a storage basket or shelf basket or toy basket for storing n organize things.  ",
                        " Modern, eye-catching and minimalistic look available in natural beige colour which compliments your home décor. Since it is handmade, it requires a day for completion and no two products are ever the same, which makes it an exclusive product.  ",
                        " Washable, Easy to maintain and clean using a damp cloth.  ",
                        " This product is quality for money. It’s very durable and its look won’t even change after several years.  "
                    ],
                    "category": "",
                    "image": [
                        "https://m.media-amazon.com/images/I/71ipeV407SL._SY450_.jpg"
                    ],
                    "rating": {
                        "rate": 5,
                        "count": 1000
                    },
                    "platforms": {
                        "amazon": "https://amzn.to/456qVja"
                    },
                    "date": 913
                },
                {
                    "id": "B08Q36V57V",
                    "title": "GLOXY Aluminium Antique Diamond Curtain Bracket Parda Holder with Support 1 Inch Rod Pocket Finials Designer Door and Window Rod Support Fittings, Curtain Rod Holder (5 Pair Gold)",
                    "price": "₹1,899.05",
                    "offer": 4599,
                    "deal": "prime deal",
                    "description": [
                        " Package Contains:- 5 Pair Single Diamond Curtain Bracket, 5 Pair Curtain Rod Support, 20 Pcs x Screw &amp; PVC Wall Grip  ",
                        " Why GLOXY ENTERPRISE curtain accessories always come with unique and luxury designs of the best quality for valued customers. As well as its weight is kept low to make it easy to use without compromising on architectural and prehistoric appearance.  ",
                        " MATERIAL : Our curtain holder brackets are made from premium quality of aluminium and go through many processes to make it superior and ancient.  ",
                        " Here we presented our curtain accessories with perfect required combination of curtain rod bracket, curtain holders and curtain rod end cap or wall to wall brackets. Also all pack comes with required screw and wall grip so you don't need to go in local market.  ",
                        " CARE INSTRUCTIONS: Avoid hard cleaners or abrasives for cleaning always use a soft cloth for cleaning  ",
                        " SUITABLE FOR: Our curtain support is best suited with 25mm / 1 Inch curtain pipe only.  "
                    ],
                    "category": "",
                    "image": [
                        "https://m.media-amazon.com/images/I/61wWFVoVYRL._SY450_.jpg"
                    ],
                    "rating": {
                        "rate": 5,
                        "count": 1000
                    },
                    "platforms": {
                        "amazon": "https://amzn.to/4582Isu"
                    },
                    "date": 753
                },
                {
                    "id": "B0933B77XQ",
                    "title": "GLOXY ENTERPRISE Aluminum Single Diamond Curtain Brackets Parda Holders with Support Fittings 1 Inch Rod Pocket Finials Designer&nbsp;Door&nbsp;and&nbsp;Window (Dark Blue 6 Pair)",
                    "price": "₹664.05",
                    "offer": 1999,
                    "deal": "prime deal",
                    "description": [
                        " <b></b>Package Contains:- 3 Pair Single Diamond Curtain Bracket, 3 Pair Curtain Rod Support, 12 Pcs x Screw &amp; PVC Wall Grip  ",
                        " <b></b>Why GLOXY ENTERPRISE curtain accessories always come with unique and luxury designs of the best quality for valued customers. As well as its weight is kept low to make it easy to use without compromising on architectural and Stylish appearance.  ",
                        " <b>MATERIAL </b>: Our curtain holder brackets are made from premium quality of aluminium and go through many processes to make it superior and ancient.  ",
                        " <b></b>Here we presented our curtain accessories with perfect required combination of curtain rod bracket, curtain holders and curtain rod end cap or wall to wall brackets. Also all pack comes with required screw and wall grip so you don't need to go in local market.  ",
                        " <b>CARE INSTRUCTIONS</b>: Avoid hard cleaners or abrasives for cleaning always use a soft cloth for cleaning  ",
                        " <b>SUITABLE FOR</b>: Our curtain support is best suited with 25mm / 1 Inch curtain pipe only.  "
                    ],
                    "category": "",
                    "image": [
                        "https://m.media-amazon.com/images/I/51GJlu5J-OS._SX466_.jpg"
                    ],
                    "rating": {
                        "rate": 5,
                        "count": 1000
                    },
                    "platforms": {
                        "amazon": "https://amzn.to/3KGkLxI"
                    },
                    "date": 132
                },
                {
                    "id": "B09DWX67QN",
                    "title": "AK Craft Beautiful Om Wooden Design Wall Hanging (Brown, 12x12 inch)",
                    "price": "₹345.00",
                    "offer": 799,
                    "deal": "prime deal",
                    "description": [
                        " Wrapped in soft paper and packed with bubble wrap and a cardboard box for reaching you safely  ",
                        " Perfect for living room bedroom kitchen office hotel dining room bathroom bar, etc  ",
                        " 1 wall hang will be available in the package  ",
                        " This product made in India and we support \"make in India\".  ",
                        " made of wood / color: orange / size : 30 x 30 cm  "
                    ],
                    "category": "",
                    "image": [
                        "https://m.media-amazon.com/images/I/81XMXTQIN8L._SX450_.jpg"
                    ],
                    "rating": {
                        "rate": 5,
                        "count": 1000
                    },
                    "platforms": {
                        "amazon": "https://amzn.to/47JOX5l"
                    },
                    "date": 841
                },
                {
                    "id": "B0788QS16V",
                    "title": "White Aura Vastu Store Copper Rahu Yantra Energised, 3 x 3 Inch, Copper",
                    "price": "₹500.00",
                    "offer": 900,
                    "deal": "prime deal",
                    "description": [
                        " Spiritually ritualized by Vastu Expert  ",
                        " 2D Rahu Yantra Made of High Quality Copper  ",
                        " Size 3\" X 3\" Rahu Yantra Copper Plate.  ",
                        " For appeasement of planet Rahu  ",
                        " Correcting vastu defects of Southwest. It is useful in normalizing highly disturbed marital relations due to South &amp; Southwest vastu defects.  "
                    ],
                    "category": "",
                    "image": [
                        "https://m.media-amazon.com/images/I/51aEZnQG+9L.jpg"
                    ],
                    "rating": {
                        "rate": 5,
                        "count": 1000
                    },
                    "platforms": {
                        "amazon": "https://amzn.to/47u4FkN"
                    },
                    "date": 204
                },
                {
                    "id": "B08LZKCR1Z",
                    "title": "GLOXYAluminium Swan Bird Shape Curtain Bracket Parda Holder with Support 1 Inch Rod Pocket Finials Designer Door and Window Rod Support Fittings, Curtain Rod Holder (Silver 5 Pair)",
                    "price": "₹1,049.00",
                    "offer": 2298,
                    "deal": "prime deal",
                    "description": [
                        " Package Contains:- 5 Pair Swan Bird Shape Curtain Bracket 5 Pair Curtain Rod Support 20 Pcs x Screw &amp; PVC Wall Grip  ",
                        " GLOXY ENTERPRISE curtain accessories always come with unique and luxury designs of the best quality for valued customers. As well as its weight is kept low to make it easy to use without compromising on architectural and Stylish appearance.  ",
                        " MATERIAL: Our curtain holder brackets are made from premium quality of Aluminium and go through many processes to make them superior and ancient.  ",
                        " Here we presented our curtain accessories with the perfect required combination of curtain rod brackets, curtain holders, and curtain rod end caps or wall-to-wall brackets. Also, all pack comes with the required screw and wall grip so you don't need to go to the local market.  ",
                        " CARE INSTRUCTIONS: Avoid hard cleaners or abrasives for cleaning always use a soft cloth for cleaning  ",
                        " SUITABLE FOR: Our curtain support is best suited with 25mm / 1 Inch curtain pipe only.  "
                    ],
                    "category": "",
                    "image": [
                        "https://m.media-amazon.com/images/I/71NCy643CiL._SY450_.jpg"
                    ],
                    "rating": {
                        "rate": 5,
                        "count": 1000
                    },
                    "platforms": {
                        "amazon": "https://amzn.to/3DWafPb"
                    },
                    "date": 561
                },
                {
                    "id": "B083FVL8Y2",
                    "title": "Chitra Zinc-Aluminium Aroma Single Support of 1 Curtain Rod Pack of 2 (Antique Finish, for 1 Inch Curtain Rod)",
                    "price": "₹475.00",
                    "offer": 510,
                    "deal": "prime deal",
                    "description": [
                        " Material: Premium Zinc-Aluminium Alloy, Finish: Antique and Installation Screws will be provided with Product  ",
                        " Suitable for 1 Inch Curtain Rod and Pocket Style Curtains  ",
                        " Support are of the Finniest quality which can be found  ",
                        " Durable,Attractive Look And Modern Design  ",
                        " Safety Warning : Clean with a soft and dry cloth to protect finish, avoid the use of household cleaners or abrasives.  "
                    ],
                    "category": "",
                    "image": [
                        "https://m.media-amazon.com/images/I/71v1lZrJVmL._SX450_.jpg"
                    ],
                    "rating": {
                        "rate": 5,
                        "count": 1000
                    },
                    "platforms": {
                        "amazon": "https://amzn.to/45kaPSQ"
                    },
                    "date": 754
                },
                {
                    "id": "B08LZHZ2BW",
                    "title": "GLOXY ENTERPRISE Aluminium Single Diamond Curtain Brackets Parda Holders with Support Fittings 1 Inch Rod Pocket Finials Designer&nbsp;Door&nbsp;and&nbsp;Window(Coffee 6 Pair)",
                    "price": "₹1,234.05",
                    "offer": 2998,
                    "deal": "prime deal",
                    "description": [
                        " <b></b>Package Contains:- 6 Pair Single Diamond Curtain Bracket, 6 Pair Curtain Rod Support, 24 Pcs x Screw &amp; PVC Wall Grip  ",
                        " <b></b>Why GLOXY ENTERPRISE curtain accessories always come with unique and luxury designs of the best quality for valued customers. As well as its weight is kept low to make it easy to use without compromising on architectural and prehistoric appearance.  ",
                        " <b>MATERIAL </b>: Our curtain holder brackets are made from premium quality of aluminium and go through many processes to make it superior and ancient.  ",
                        " <b></b>Here we presented our curtain accessories with perfect required combination of curtain rod bracket, curtain holders and curtain rod end cap or wall to wall brackets. Also all pack comes with required screw and wall grip so you don't need to go in local market.  ",
                        " <b>CARE INSTRUCTIONS</b>: Avoid hard cleaners or abrasives for cleaning always use a soft cloth for cleaning  ",
                        " <b>SUITABLE FOR</b>: Our curtain support is best suited with 25mm / 1 Inch curtain pipe only.  "
                    ],
                    "category": "",
                    "image": [
                        "https://m.media-amazon.com/images/I/61ZVNYyxDgL._SX466_.jpg"
                    ],
                    "rating": {
                        "rate": 5,
                        "count": 1000
                    },
                    "platforms": {
                        "amazon": "https://amzn.to/47zWdAn"
                    },
                    "date": 454
                },
                {
                    "id": "B083SN6YQY",
                    "title": "Chitra Brass Oval End Cap for 1 Inch Dia Curtain Rod Pack of 4 (Antique Marvel)",
                    "price": "₹449.00",
                    "offer": 480,
                    "deal": "prime deal",
                    "description": [
                        " Material: Brass, Finish: Antique Marvel and Installation Screws will be provided with Product  ",
                        " Suitable for 1 Inch Curtain Rod and Pocket Style Curtains  ",
                        " Oval Zinc Concealed Bracket are of the Finniest quality which can be found  ",
                        " Durable,Attractive Look And Modern Design  ",
                        " Safety Warning : Clean with a soft and dry cloth to protect finish, avoid the use of household cleaners or abrasives.  "
                    ],
                    "category": "",
                    "image": [
                        "https://m.media-amazon.com/images/I/613QrCg-zXL._SX450_.jpg"
                    ],
                    "rating": {
                        "rate": 5,
                        "count": 1000
                    },
                    "platforms": {
                        "amazon": "https://amzn.to/47zWrHJ"
                    },
                    "date": 844
                },
                {
                    "id": "B0933B9GR1",
                    "title": "GLOXY ENTERPRISE Aluminium Single Diamond Curtain Brackets Parda Holders with Support Fittings 1 Inch Rod Pocket Finials Designer&nbsp;Door&nbsp;and&nbsp;Window (Antique 12 Pair)",
                    "price": "₹1,234.05",
                    "offer": 3099,
                    "deal": "prime deal",
                    "description": [
                        " <b></b>Package Contains:- 6 Pair Single Diamond Curtain Bracket, 6 Pair Curtain Rod Support, 24 Pcs x Screw &amp; PVC Wall Grip  ",
                        " <b></b>Why GLOXY ENTERPRISE curtain accessories always come with unique and luxury designs of the best quality for valued customers. As well as its weight is kept low to make it easy to use without compromising on architectural and prehistoric appearance.  ",
                        " <b>MATERIAL </b>: Our curtain holder brackets are made from premium quality of aluminium and go through many processes to make it superior and ancient.  ",
                        " <b></b>Here we presented our curtain accessories with perfect required combination of curtain rod bracket, curtain holders and curtain rod end cap or wall to wall brackets. Also all pack comes with required screw and wall grip so you don't need to go in local market.  ",
                        " <b>CARE INSTRUCTIONS</b>: Avoid hard cleaners or abrasives for cleaning always use a soft cloth for cleaning  ",
                        " <b>SUITABLE FOR</b>: Our curtain support is best suited with 25mm / 1 Inch curtain pipe only.  "
                    ],
                    "category": "",
                    "image": [
                        "https://m.media-amazon.com/images/I/6136-kENWnS._SX466_.jpg"
                    ],
                    "rating": {
                        "rate": 5,
                        "count": 1000
                    },
                    "platforms": {
                        "amazon": "https://amzn.to/47xkyGZ"
                    },
                    "date": 319
                },
                {
                    "id": "B0987RGVC8",
                    "title": "Hopdezyo Antique Nautical Wooden Tripod Adjustable Floor Lamp for Living Room, Home Decoration, Pack of Lamp in Jute Shade",
                    "price": "₹1,090.00",
                    "offer": 2100,
                    "deal": "prime deal",
                    "description": [
                        " Jute Shade Size 11 x 12 Inch  ",
                        " Made with high-quality material  ",
                        " Ideal for decorating homes and gifting purposes.  ",
                        " Made In India  "
                    ],
                    "category": "",
                    "image": [
                        "https://m.media-amazon.com/images/I/51yO5Lu2jqL._SY606_.jpg"
                    ],
                    "rating": {
                        "rate": 5,
                        "count": 1000
                    },
                    "platforms": {
                        "amazon": "https://amzn.to/3DTwS75"
                    },
                    "date": 797
                },
                {
                    "id": "B0987QC96Y",
                    "title": "Hopdezyo Yellow Drum Lamp Shade Corner Floor Lamp for Living Room, Bedroom, Office, Home Decoration",
                    "price": "₹1,499.00",
                    "offer": 4500,
                    "deal": "prime deal",
                    "description": [
                        " 12 inch diameter Shade  ",
                        " Round Modern Yellow Shade  ",
                        " Teak wood floor lamp  ",
                        " Ideal for decorating homes and gifting purposes  ",
                        " Handmade in India  "
                    ],
                    "category": "",
                    "image": [
                        "https://m.media-amazon.com/images/I/61qk8I9VthL._SX466_.jpg"
                    ],
                    "rating": {
                        "rate": 5,
                        "count": 1000
                    },
                    "platforms": {
                        "amazon": "https://amzn.to/47yCS2q"
                    },
                    "date": 707
                },
                {
                    "id": "B08J3ZFWQ2",
                    "title": "DIVERGE FURNISHINGS Polyester Flower Print Long Crush 3 Piece Door Curtains | 7 Feet Printed Polyester Blend Curtain for Bedroom | Eyelet Grommet Room Darkening Drapes for Kitchen &amp; Hall | Floral Parda | 7Ft, Green",
                    "price": "₹799.00",
                    "offer": "",
                    "deal": "prime deal",
                    "description": [
                        " Product Dimensions : Door Curtain - 3 Piece - 7 x 4 Feet, 85 x 48 Inches or 217 x 122 Cm  ",
                        " Material Quality:- 100% High-Quality Polyester Blend Which Has A Natural Resistance To Shrinkage And Wrinkles Making Them Easy To Maintain. It Gives A Much-Desired Sassy Look.Readymade Curtains Ideal For Living Room Or Bedroom.Vibrant Designing in Rich and Sharp Colors  ",
                        " Light Filtering:- These Curtains Are The Solution If You Feel Daylight Negatively Affect Your Sleep Or Rest, Are Sensitive To Light, Or Work Odd Hours As They Can Block Light Of The Room. They Also Ensure Total Privacy For You And Your Loved Ones.  ",
                        " Energy Smart Protection:- Light-Filtering Soft Fabric Balances Room Temperature By Insulating Against Summer Heat And Winter Chill. Protect Your Furniture, Floors And Artwork From The Ravages Of The Sun, The Drapes Will Pay For Themselves Off Over Time.Beautiful for Your Home and for Gifting.This Room Darkenninf curtain can block out up to 70% of light. Thus illuminates the room.  ",
                        " Package Content- 3 Panels Curtains with Expert Workmanship with 8 Eyelets for Easy Hanging On Top  ",
                        " Wash Care:- Normal Hand Wash Or Machine Wash [Light Colors To Be Wash Separately]  "
                    ],
                    "category": "",
                    "image": [
                        "https://m.media-amazon.com/images/I/51O6evDmxnL._SX466_.jpg"
                    ],
                    "rating": {
                        "rate": 5,
                        "count": 1000
                    },
                    "platforms": {
                        "amazon": "https://amzn.to/45sM4DW"
                    },
                    "date": 547
                },
                {
                    "id": "B08F2VDRSB",
                    "title": "DIVERGE FURNISHINGS Polyester Blend 2 Piece Door Curtains | 7 Feet Printed Polyester Blend Curtain for Bedroom | Eyelet Grommet Room Darkening Drapes for Kitchen &amp; Hall | Polyester Blend Parda | 7Ft, Grey",
                    "price": "₹749.00",
                    "offer": "",
                    "deal": "prime deal",
                    "description": [
                        " Product Dimensions : Door Curtain - 2 Piece - 7 x 4 Feet, 85 x 48 Inches or 217 x 122 Cm  ",
                        " Material Quality:- 100% High-Quality Velvet Which Has A Natural Resistance To Shrinkage And Wrinkles Making Them Easy To Maintain. It Gives A Much-Desired Sassy Look.Readymade Curtains Ideal For Living Room Or Bedroom.Vibrant Designing in Rich and Sharp Colors  ",
                        " Light Filtering:- These Curtains Are The Solution If You Feel Daylight Negatively Affect Your Sleep Or Rest, Are Sensitive To Light, Or Work Odd Hours As They Can Block Light Of The Room. They Also Ensure Total Privacy For You And Your Loved Ones.  ",
                        " Energy Smart Protection:- Light-Filtering Soft Fabric Balances Room Temperature By Insulating Against Summer Heat And Winter Chill. Protect Your Furniture, Floors And Artwork From The Ravages Of The Sun, The Drapes Will Pay For Themselves Off Over Time.Beautiful for Your Home and for Gifting.This Room Darkenninf curtain can block out up to 70% of light. Thus illuminates the room.  ",
                        " Package Content- 2 Panels Curtains with Expert Workmanship with 8 Eyelets for Easy Hanging On Top  ",
                        " Wash Care:- Normal Hand Wash Or Machine Wash [Light Colors To Be Wash Separately]  "
                    ],
                    "category": "",
                    "image": [
                        "https://m.media-amazon.com/images/I/81gwVQ7bZJS._SX466_.jpg"
                    ],
                    "rating": {
                        "rate": 5,
                        "count": 1000
                    },
                    "platforms": {
                        "amazon": "https://amzn.to/455Oicl"
                    },
                    "date": 394
                },
                {
                    "id": "B08LZM775J",
                    "title": "GLOXY ENTERPRISE Aluminium Single Diamond Curtain Brackets Parda Holders with Support Fittings 1 Inch Rod Pocket Finials Designer&nbsp;Door&nbsp;and&nbsp;Window(Silver 5 Pair)",
                    "price": "₹996.55",
                    "offer": 2098,
                    "deal": "prime deal",
                    "description": [
                        " <b></b>Package Contains:- 5 Pair Single Diamond Curtain Bracket, 5 Pair Curtain Rod Support, 20 Pcs x Screw &amp; PVC Wall Grip  ",
                        " <b></b>Why GLOXY ENTERPRISE curtain accessories always come with unique and luxury designs of the best quality for valued customers. As well as its weight is kept low to make it easy to use without compromising on architectural and Stylish appearance.  ",
                        " <b>MATERIAL </b>: Our curtain holder brackets are made from premium quality of aluminium and go through many processes to make it superior and ancient.  ",
                        " <b></b>Here we presented our curtain accessories with perfect required combination of curtain rod bracket, curtain holders and curtain rod end cap or wall to wall brackets. Also all pack comes with required screw and wall grip so you don't need to go in local market.  ",
                        " <b>CARE INSTRUCTIONS</b>: Avoid hard cleaners or abrasives for cleaning always use a soft cloth for cleaning  ",
                        " <b>SUITABLE FOR</b>: Our curtain support is best suited with 25mm / 1 Inch curtain pipe only.  "
                    ],
                    "category": "",
                    "image": [
                        "https://m.media-amazon.com/images/I/6196tK3kqCL._SX466_.jpg"
                    ],
                    "rating": {
                        "rate": 5,
                        "count": 1000
                    },
                    "platforms": {
                        "amazon": "https://amzn.to/458Ddaz"
                    },
                    "date": 183
                },
                {
                    "id": "B08J4G38C7",
                    "title": "DIVERGE FURNISHINGS Polyester Flower Print Long Crush 2 Piece Door Curtains | 7 Feet Printed Polyester Blend Curtain for Bedroom | Eyelet Grommet Room Darkening Drapes for Kitchen &amp; Hall | Floral Parda | 7Ft, Pink",
                    "price": "₹649.00",
                    "offer": "",
                    "deal": "prime deal",
                    "description": [
                        " Product Dimensions : Door Curtain - 2 Piece - 7 x 4 Feet, 85 x 48 Inches or 217 x 122 Cm  ",
                        " Material Quality:- 100% High-Quality Polyester Blend Which Has A Natural Resistance To Shrinkage And Wrinkles Making Them Easy To Maintain. It Gives A Much-Desired Sassy Look.Readymade Curtains Ideal For Living Room Or Bedroom.Vibrant Designing in Rich and Sharp Colors  ",
                        " Light Filtering:- These Curtains Are The Solution If You Feel Daylight Negatively Affect Your Sleep Or Rest, Are Sensitive To Light, Or Work Odd Hours As They Can Block Light Of The Room. They Also Ensure Total Privacy For You And Your Loved Ones.  ",
                        " Energy Smart Protection:- Light-Filtering Soft Fabric Balances Room Temperature By Insulating Against Summer Heat And Winter Chill. Protect Your Furniture, Floors And Artwork From The Ravages Of The Sun, The Drapes Will Pay For Themselves Off Over Time.Beautiful for Your Home and for Gifting.This Room Darkenninf curtain can block out up to 70% of light. Thus illuminates the room.  ",
                        " Package Content- 2 Panels Curtains with Expert Workmanship with 8 Eyelets for Easy Hanging On Top  ",
                        " Wash Care:- Normal Hand Wash Or Machine Wash [Light Colors To Be Wash Separately]  "
                    ],
                    "category": "",
                    "image": [
                        "https://m.media-amazon.com/images/I/61DdO7W94jL.jpg"
                    ],
                    "rating": {
                        "rate": 5,
                        "count": 1000
                    },
                    "platforms": {
                        "amazon": "https://amzn.to/45kb7co"
                    },
                    "date": 33
                },
                {
                    "id": "B09DWBJNPR",
                    "title": "VCH Pooja Aasan Perfect,aasan mats, Pooja mats for aasan,(2 * 2 Foot /24 * 24 Inches, Set of 5pc,2X2",
                    "price": "₹619.00",
                    "offer": 999,
                    "deal": "prime deal",
                    "description": [
                        " ESY TO WASH  ",
                        " ABSTRACT PATTERN  "
                    ],
                    "category": "",
                    "image": [
                        "https://m.media-amazon.com/images/I/71X1HD3C0kL._SX466_.jpg"
                    ],
                    "rating": {
                        "rate": 5,
                        "count": 1000
                    },
                    "platforms": {
                        "amazon": "https://amzn.to/3E1UmXr"
                    },
                    "date": 0
                },
                {
                    "id": "B09CZBRLCX",
                    "title": "CULTURWAY - Rose Wood 3 in 1 Cutlery Holder - Caddy Cutlery Stand with Salt Shakers and Tissue Paper Holder for Dinning Table and Kitchen Stylish- in Walnut Color (15 X 15.5 X 11.5 cms)",
                    "price": "₹499.00",
                    "offer": 999,
                    "deal": "prime deal",
                    "description": [
                        " Organize your Tableware: In our caddy you can easily and neatly store spoons, forks, chopsticks, knife set with any hassle.  ",
                        " This Cutlery holder for dining table stylish solution for messy table and kitchen with two Ceramic Salt and Pepper shakers.  ",
                        " This Premium Cutlery Holder Big and Tall is Handcrafted. Cutlery Holder dining table stylish and Modern Presence with a tissue paper holder for dining table stylish  ",
                        " Cutlery Holder Wooden Dimension with Tissue Paper Holder and Salt Shakers( 15 X 15.5 X 11.5 cms )  ",
                        " This Cutlery holder Premium Build Best Quality Beautifully Handcrafted in India.  "
                    ],
                    "category": "",
                    "image": [
                        "https://m.media-amazon.com/images/I/81mNiwZvfWL._SX466_.jpg"
                    ],
                    "rating": {
                        "rate": 5,
                        "count": 1000
                    },
                    "platforms": {
                        "amazon": "https://amzn.to/452WV7E"
                    },
                    "date": 810
                },
                {
                    "id": "B097T52WKD",
                    "title": "Hopdezyo Cotton E27 Led Italian Crafter Home Decor Floor Lamp For Living Room Bedroom Office, Holder &amp; Lamp, Black Shade, Pack of 1",
                    "price": "₹1,499.00",
                    "offer": 6600,
                    "deal": "prime deal",
                    "description": [
                        " Best Lighting Solution  ",
                        " Adjustable Lighting  ",
                        " Stunning Design  ",
                        " Easy installation  ",
                        " Made with high-quality material  "
                    ],
                    "category": "",
                    "image": [
                        "https://m.media-amazon.com/images/I/61yQc7Wc44L._SX466_.jpg"
                    ],
                    "rating": {
                        "rate": 5,
                        "count": 1000
                    },
                    "platforms": {
                        "amazon": "https://amzn.to/47vTdVK"
                    },
                    "date": 107
                },
                {
                    "id": "B0BZQNXX48",
                    "title": "Walleaf™ Hanuman idol designer wooden Wall Hanging | Hanuman chalisa | Home Decor | Gifts | Wall Art For Hall &amp; Temple Room",
                    "price": "₹299.00",
                    "offer": 598,
                    "deal": "prime deal",
                    "description": [
                        " Material &amp; Durability :- Made by professional artist on good quality wood, non-fading till life long. Stretcher bar, ready for hang  ",
                        " Easy to Clean :- This wall hanging item is easy to clean and maintain. You can dust the dirt off it by just using a soft cloth.  ",
                        " Be Inspired With Positive Messages About Love, Family And Appreciating Each Other! Our Inspirational Wall Decor Is A Perfect Feel-Good  ",
                        " Material of frame: Pine wood; easy to set up, Wall Mount collage , suitable for your home, office, bedroom walls decoration with your stunning memories  "
                    ],
                    "category": "",
                    "image": [
                        "https://m.media-amazon.com/images/I/71MsDHFi3QL._SY606_.jpg"
                    ],
                    "rating": {
                        "rate": 5,
                        "count": 1000
                    },
                    "platforms": {
                        "amazon": "https://amzn.to/3KEIYVt"
                    },
                    "date": 879
                },
                {
                    "id": "B089WBJPPP",
                    "title": "DIVERGE FURNISHINGS Polyester Jacquard Tree 2 Piece Window Curtains | 5 Feet Printed Polyester Blend Curtain for Bedroom | Eyelet Grommet Room Darkening Drapes for Kitchen &amp; Hall | Jute Floral Parda | 5Ft, Maroon",
                    "price": "₹649.00",
                    "offer": "",
                    "deal": "prime deal",
                    "description": [
                        " Product Dimensions : Window Curtain - 2 Piece - 5 x 4 Feet, 60 x 48 Inches or 152 x 122 Cm  ",
                        " Material Quality:- 100% High-Quality Jute Which Has A Natural Resistance To Shrinkage And Wrinkles Making Them Easy To Maintain. It Gives A Much-Desired Sassy Look.Readymade Curtains Ideal For Living Room Or Bedroom.Vibrant Designing in Rich and Sharp Colors  ",
                        " Light Filtering:- These Curtains Are The Solution If You Feel Daylight Negatively Affect Your Sleep Or Rest, Are Sensitive To Light, Or Work Odd Hours As They Can Block Light Of The Room. They Also Ensure Total Privacy For You And Your Loved Ones.  ",
                        " Energy Smart Protection:- Light-Filtering Soft Fabric Balances Room Temperature By Insulating Against Summer Heat And Winter Chill. Protect Your Furniture, Floors And Artwork From The Ravages Of The Sun, The Drapes Will Pay For Themselves Off Over Time.Beautiful for Your Home and for Gifting.This Room Darkenninf curtain can block out up to 70% of light. Thus illuminates the room.  ",
                        " Package Content- 2 Panels Curtains with Expert Workmanship with 8 Eyelets for Easy Hanging On Top  ",
                        " Wash Care:- Normal Hand Wash Or Machine Wash [Light Colors To Be Wash Separately]  "
                    ],
                    "category": "",
                    "image": [
                        "https://m.media-amazon.com/images/I/51i3nM3U5cL.jpg"
                    ],
                    "rating": {
                        "rate": 5,
                        "count": 1000
                    },
                    "platforms": {
                        "amazon": "https://amzn.to/3quacqO"
                    },
                    "date": 414
                },
                {
                    "id": "B083SNFYCH",
                    "title": "Chitra Brass Big Wall to Wall Concealed Bracket for 1 Inch Dia Curtain Rod Pack of 2 (Silver)",
                    "price": "₹399.00",
                    "offer": 400,
                    "deal": "prime deal",
                    "description": [
                        " Material: Brass, Finish: Silver and Installation Screws will be provided with Product  ",
                        " Suitable for 1 Inch Curtain Rod and Pocket Style Curtains  ",
                        " Icon Brass Concealed Bracket are of the Finniest quality which can be found  ",
                        " Durable,Attractive Look And Modern Design  ",
                        " Safety Warning : Clean with a soft and dry cloth to protect finish, avoid the use of household cleaners or abrasives.  "
                    ],
                    "category": "",
                    "image": [
                        "https://m.media-amazon.com/images/I/61mbtCSWsxL._SX450_.jpg"
                    ],
                    "rating": {
                        "rate": 5,
                        "count": 1000
                    },
                    "platforms": {
                        "amazon": "https://amzn.to/47zhYAk"
                    },
                    "date": 932
                },
                {
                    "id": "B0C7MRBMTF",
                    "title": "Mridang Handmade Engagement Ring tray ring holder platter with customise name(anniversary/engagement/wedding ring platter/Decorative Tray/Marriage Decor)- 10x10x8 INCH",
                    "price": "₹1,499.00",
                    "offer": 2999,
                    "deal": "prime deal",
                    "description": [
                        " Large and attractive : Dimension - (10x10x8) 10 INCH width and 10 INCH length or 8 INCH Hight. This engagement ring tray&lt;br&gt;  ",
                        " USE: Specially made for engagement ring ceremony purpose .&lt;br&gt;  ",
                        " Completely Handmade: This ring plate completely handmade products. We use MDF,Korean flower and decorated items to make this masterpiece.&lt;br&gt;  ",
                        " Classy Look: A personalised ring holder for your engagement rings with your names.&lt;br&gt;  ",
                        " Send your matters with your amazon order id in the whatsapp  "
                    ],
                    "category": "",
                    "image": [
                        "https://m.media-amazon.com/images/I/71mC-XtdIDL._SY679_.jpg"
                    ],
                    "rating": {
                        "rate": 5,
                        "count": 1000
                    },
                    "platforms": {
                        "amazon": "https://amzn.to/3KGeSRv"
                    },
                    "date": 6
                },
                {
                    "id": "B0C1GX5D2C",
                    "title": "Xtreme PRO Direct Theme Printed Computer Desk for Gaming, Work &amp; Study Table. Large for PC, Dual Monitors, iMac, and Laptops. Carbon Steel &amp; Engineered Wood. Black Stand. 4 Feet. XPB4_CT-300",
                    "price": "₹9,740.00",
                    "offer": 14000,
                    "deal": "prime deal",
                    "description": [
                        " THIS FUTURISTIC, FEATURE RICH GAMING, WORK &amp; STUDY DESK measures: 48 inch(L) * 22 inch(W) * 30 inch(H). Double Laminated 18mm engineered wood with Silver Nano technology which reduces microbial growth on the surface by 99.9%. Water &amp; scratch resistant. A Double wood strip in front of the table for added strength. Comes with a Unique Table Base Design. Adjustable desk feet, stabilizing your work &amp; gaming station on any surface.  ",
                        " ECO-FRIENDLY SMOOTH SURFACE. STRONG &amp; STURDY COMPUTER WORK, STUDY &amp; PLAY DESK. Theme-based direct ceramic printed table tops to match your style. Print on the table is NOT A STICKER. Does not shake or wobble while working, gaming, or studying. This 4 Feet unique anti-rust desk has a sleek carbon fiber texture powder-coated steel frame for long life of up to 45 years.  ",
                        " INCLUDES power extension box holder below the table, 50mm grommet on the table top along with 6 zip ties for cable management. Headphone Holder &amp; stainless steel pen pencil (Stationery) holder in front of the table, convenient for professionals and students. Hi tech printed Keyboard and mouse Mat included. The table surface has plenty of space for DUAL MONITORS and GAMING MONITOR up to 43 inches, mid-tower desktop PC, gaming keyboard &amp; mouse, and other gaming gear for a good gaming experience.  ",
                        " DIY. Toolkit &amp; Instructions manual included with the table. A Three-piece table that can be assembled easily. A dismantlable desk for easy transport. A single-piece table top which is convenient for work, study, and play. Carbon Steel Frame can withstand the weight of more than 80 kgs / 176lbs  ",
                        " WE MAKE SURE we're designing and manufacturing the best gaming, work &amp; study desks in India for the world. Being manufacturers, we take pride in our innovative designs, printing, multiple coating processes, rigorous testing, and world-class manufacturing process. We manufacture our products to a high standard and hope you have an excellent experience when enjoying our computer, Laptop desks.  "
                    ],
                    "category": "",
                    "image": [
                        "https://m.media-amazon.com/images/I/813ZdWQgnJL._SX466_.jpg"
                    ],
                    "rating": {
                        "rate": 5,
                        "count": 1000
                    },
                    "platforms": {
                        "amazon": "https://amzn.to/47xZVe4"
                    },
                    "date": 348
                },
                {
                    "id": "B0CB1M3N2Y",
                    "title": "KYNE Wicker Basket for Modular Kitchen For Vegetables and Fruits Storage Pull Out Basket (16 W X 20 L X 8 H)",
                    "price": "₹2,374.00",
                    "offer": 5999,
                    "deal": "prime deal",
                    "description": [
                        " Suitable for 450mm Cabinet from outside to outside  ",
                        " Highly durable easy to wash, maintain and long life product  ",
                        " Free wooden channels/runners  ",
                        " Dimensions: 414mm Width Including Channel x 500mm Depth x 200mm Height  "
                    ],
                    "category": "",
                    "image": [
                        "https://m.media-amazon.com/images/I/81Eq7MIyNTL._SY450_.jpg"
                    ],
                    "rating": {
                        "rate": 5,
                        "count": 1000
                    },
                    "platforms": {
                        "amazon": "https://amzn.to/3YwMKpy"
                    },
                    "date": 227
                },
                {
                    "id": "B08X2VQ85V",
                    "title": "LFM EBCO Galvanized Mild Steel 4 Inch Bed CLAMP Fitting for Double Bed Single Bed Easy Fit for A King Size Double Bed with Extended ARM Dub (4'') 8N (1 Set) (4 INCH CLAMP)",
                    "price": "₹530.00",
                    "offer": 650,
                    "deal": "prime deal",
                    "description": null,
                    "category": "",
                    "image": [
                        "https://m.media-amazon.com/images/I/415fEd+QVOL._SX466_.jpg"
                    ],
                    "rating": {
                        "rate": 5,
                        "count": 1000
                    },
                    "platforms": {
                        "amazon": "https://amzn.to/3OCo0Ye"
                    },
                    "date": 510
                },
                {
                    "id": "B0C5QVRX7M",
                    "title": "Elegent Home Coffee Table for Living Room Bed Side Table Coffee Table for Garden 3 Legs Table Round top Metal Coffee Table end Table Green (Small)",
                    "price": "₹999.00",
                    "offer": 2200,
                    "deal": "prime deal",
                    "description": [
                        " <b>Product Dimension L x B X H </b>- 20x20x22 inch large and small -15x15x18 inch  "
                    ],
                    "category": "",
                    "image": [
                        "https://m.media-amazon.com/images/I/61WvcWXjcsL._SX466_.jpg"
                    ],
                    "rating": {
                        "rate": 5,
                        "count": 1000
                    },
                    "platforms": {
                        "amazon": "https://amzn.to/3Ywre4d"
                    },
                    "date": 365
                },
                {
                    "id": "B08B8VWWWM",
                    "title": "DIVERGE FURNISHINGS Polyester Plain Crush 1 Piece Door Curtains | 7 Feet Plain Curtain for Bedroom | Eyelet Grommet Semi Transparent Drapes for Kitchen &amp; Hall | Polyester Blend Parda | 7Ft, Brown",
                    "price": "₹239.00",
                    "offer": "",
                    "deal": "prime deal",
                    "description": [
                        " Product Dimensions : Door Curtain - 1 Piece - 7 x 4 Feet, 85 x 48 Inches or 217 x 122 Cm  ",
                        " Material Quality:- 100% High-Quality Polyester Blend Which Has A Natural Resistance To Shrinkage And Wrinkles Making Them Easy To Maintain. It Gives A Much-Desired Sassy Look.Readymade Curtains Ideal For Living Room Or Bedroom.Vibrant Designing in Rich and Sharp Colors  ",
                        " Light Filtering:- These Curtains Are The Solution If You Feel Daylight Negatively Affect Your Sleep Or Rest, Are Sensitive To Light, Or Work Odd Hours As They Can Block Light Of The Room. They Also Ensure Total Privacy For You And Your Loved Ones.  ",
                        " Energy Smart Protection:- Light-Filtering Soft Fabric Balances Room Temperature By Insulating Against Summer Heat And Winter Chill. Protect Your Furniture, Floors And Artwork From The Ravages Of The Sun, The Drapes Will Pay For Themselves Off Over Time.Beautiful for Your Home and for Gifting.This Room Darkenninf curtain can block out up to 70% of light. Thus illuminates the room.  ",
                        " Package Content- 1 Panels Curtains with Expert Workmanship with 8 Eyelets for Easy Hanging On Top  ",
                        " Wash Care:- Normal Hand Wash Or Machine Wash [Light Colors To Be Wash Separately]  "
                    ],
                    "category": "",
                    "image": [
                        "https://m.media-amazon.com/images/I/41ShPEUbvEL.jpg"
                    ],
                    "rating": {
                        "rate": 5,
                        "count": 1000
                    },
                    "platforms": {
                        "amazon": "https://amzn.to/3OTpHlC"
                    },
                    "date": 723
                },
                {
                    "id": "B08NT5GQ5P",
                    "title": "Shri Shiva Panchakshari Yantra (Copper) -3 inches",
                    "price": "₹500.00",
                    "offer": 950,
                    "deal": "prime deal",
                    "description": [
                        " Shiva Panchakshari Yantra protects one in all aspects with the Holy names of Lord Shiva. Shiva Panchakshari Yantra helps one greatly to radiate Light on the spiritual path.  ",
                        " Shiva Panchakshari Yantra is very good yantra for healing body, mind and soul.  ",
                        " The Shiva Panchakshri yantra works like a weapon against the negative influence of malefic planets  "
                    ],
                    "category": "",
                    "image": [
                        "https://m.media-amazon.com/images/I/61+5ei9o3dL._SX450_.jpg"
                    ],
                    "rating": {
                        "rate": 5,
                        "count": 1000
                    },
                    "platforms": {
                        "amazon": "https://amzn.to/3QGV7Ng"
                    },
                    "date": 762
                },
                {
                    "id": "B0C77NLR7M",
                    "title": "Aaradhak Divine Illumination Lord Shiva Wall Hanging Decorative Showpiece | Wooden Design Cutout into Mahadev Face | Best for Home Decor | Without Light (15 Iinches)",
                    "price": "₹1,349.00",
                    "offer": 1999,
                    "deal": "prime deal",
                    "description": [
                        " Exquisite Shiva Wall Hanging decorative showpiece. Black Polished with MDF cut-out design featuring Lord Shiva.  ",
                        " Our wall hanging is made in India from wood which embarks the elegance. This peice is of 15 Inches and without lights, also available in different sizes.  ",
                        " Our Wall Hanging is Elegant and Durable construction with high-quality materials which enhances your sacred space for meditation and prayer.  ",
                        " Easy to Clean :- This wall hanging item is easy to clean and maintain. You can dust the dirt off it by just using a soft cloth.  ",
                        " A perfect gift : It is a best gift for marriage anniversary , parents , mothers day , wedding gift , birthday , house warming , office. Suitable for home, office, teahouse, meditation, etc. Used in home, warm and comfortable, reflects your elegant taste.  "
                    ],
                    "category": "",
                    "image": [
                        "https://m.media-amazon.com/images/I/71R7Ntng0TL._SX466_.jpg"
                    ],
                    "rating": {
                        "rate": 5,
                        "count": 1000
                    },
                    "platforms": {
                        "amazon": "https://amzn.to/3qoyJ0C"
                    },
                    "date": 461
                },
                {
                    "id": "B0B9CGK9GF",
                    "title": "F N Wood Finautica Natural Standard Size Tower Floor Lamp Home Decors Gift, Brown",
                    "price": "₹1,090.00",
                    "offer": 4500,
                    "deal": "prime deal",
                    "description": [
                        " Easy installation  ",
                        " Made In India  "
                    ],
                    "category": "",
                    "image": [
                        "https://m.media-amazon.com/images/I/51W4oQn8jBL.jpg"
                    ],
                    "rating": {
                        "rate": 5,
                        "count": 1000
                    },
                    "platforms": {
                        "amazon": "https://amzn.to/45sNWfP"
                    },
                    "date": 686
                },
                {
                    "id": "B0BSBBQ23F",
                    "title": "Mituja Canvas Painting Hanuman Chalisa Religious Art Painting for Living Room | Wall Decor | Bedroom | Pooja Ghar | Office | Hotel | Rajasthani Art (Large Unframed - 35 X 26 inches) 09",
                    "price": "₹806.00",
                    "offer": 1899,
                    "deal": "prime deal",
                    "description": [
                        " Painting Dimesion - 35 Inches X 26 Inches with additional border for framing. NOTE: These are Unframed Paintings.  ",
                        " ARTISTIC ELEGANCE: Our paintings are designed to bring a touch of creativity and individuality to any room in your home or office. OUR CANVAS PAINTINGS, combined with RICH and VIBRANT COLORS, creates a stunning piece of art that will become a focal point in any space.  ",
                        " LONG-LASTING QUALITY: The use of 100% PURE COTTON CANVAS and NON-TOXIC HIGH-QUALITY INKS means that our paintings are made to last. The high-quality materials ensure that the colors will remain vibrant for years to come, so you can enjoy your art for a LONG TIME.  ",
                        " PERSONALIZED TOUCH: We give the option for unframed paintings so you can have the freedom to choose a frame that matches your personal style and dcor. Whether you prefer a minimalist or elaborate frame, you can easily customize your art to fit your taste.  ",
                        " PERFECT GIFT: If you're looking for a thoughtful and unique gift, look no further. This paintings are the perfect choice, thanks to the HIGH-QUALITY MATERIALS, UNIQUE DESIGN, and versatility in both home and office settings. Impress your friends and family with a gift that showcases your excellent taste and thoughtfulness. To see more of our high-quality canvas paintings, simply search for \"MITUJA CANVAS PAINTINGS\" in the Amazon search bar. You'll find an array of unique designs and styles.  "
                    ],
                    "category": "",
                    "image": [
                        "https://m.media-amazon.com/images/I/A1HeOipPI4L._SX466_.jpg"
                    ],
                    "rating": {
                        "rate": 5,
                        "count": 1000
                    },
                    "platforms": {
                        "amazon": "https://amzn.to/45tatcA"
                    },
                    "date": 463
                },
                {
                    "id": "B0933B9GQV",
                    "title": "GLOXY ENTERPRISE Aluminium Diamond Brackets Parda Holder with Support 1 Inch Curtains Rod Pocket Finials Designer Door and Window Curtain Holders and Rod Support Fittings(Golden 12 Pair)",
                    "price": "₹1,234.05",
                    "offer": 3099,
                    "deal": "prime deal",
                    "description": [
                        " <b></b>Package Contains:- 6 Pair Single Diamond Curtain Bracket, 6 Pair Curtain Rod Support, 24 Pcs x Screw &amp; PVC Wall Grip  ",
                        " <b></b>Why GLOXY ENTERPRISE curtain accessories always come with unique and luxury designs of the best quality for valued customers. As well as its weight is kept low to make it easy to use without compromising on architectural and prehistoric appearance.  ",
                        " <b>MATERIAL </b>: Our curtain holder brackets are made from premium quality of aluminium and go through many processes to make it superior and ancient.  ",
                        " <b></b>Here we presented our curtain accessories with perfect required combination of curtain rod bracket, curtain holders and curtain rod end cap or wall to wall brackets. Also all pack comes with required screw and wall grip so you don't need to go in local market.  ",
                        " <b>CARE INSTRUCTIONS</b>: Avoid hard cleaners or abrasives for cleaning always use a soft cloth for cleaning  ",
                        " <b>SUITABLE FOR</b>: Our curtain support is best suited with 25mm / 1 Inch curtain pipe only.  "
                    ],
                    "category": "",
                    "image": [
                        "https://m.media-amazon.com/images/I/614QyQUb2mS._SX466_.jpg"
                    ],
                    "rating": {
                        "rate": 5,
                        "count": 1000
                    },
                    "platforms": {
                        "amazon": "https://amzn.to/3OWYFtJ"
                    },
                    "date": 536
                }
            ]
        }

        item.Amazon.forEach(async (element) => {

            try {
                const docRef = await addDoc(collection(db, "products"), {
                    element
                });
                console.log("Document written with ID: ", docRef.id);
            } catch (e) {
                console.error("Error adding document: ", e);
            }

        })


    }








    return (
        <>
            <div className="container pt-5">
                <div className="text-center mb-4">
                    <form onSubmit={handleSubmit}>
                        <div className='row'>
                            <div className='col-lg-6'>
                                <input className="form-control" type="text" style={styles.input} placeholder="Title" name="title" value={formData.title} onChange={handleChange} />
                            </div>
                            <div className='col-lg-6'>
                                <input className="form-control" type="text" style={styles.input} placeholder="price" name="price" value={formData.price} onChange={handleChange} />
                            </div>
                        </div>
                        <br />
                        <div className='row'>
                            <div className='col-lg-6'>
                                <input className="form-control" type="text" style={styles.input} placeholder="Offer" name="offer" value={formData.offer} onChange={handleChange} />
                            </div>
                            <div className='col-lg-6'>
                                <input className="form-control" type="text" style={styles.input} placeholder="Deal" name="deal" value={formData.deal} onChange={handleChange} />
                            </div>
                        </div>
                        <br />
                        <div className='row'>
                            <div className='col-lg-6'>
                                <input className="form-control" type="text" style={styles.input} placeholder="Description" name="description" value={formData.description} onChange={handleChange} />
                            </div>
                            <div className='col-lg-6'>
                                <input className="form-control" type="text" style={styles.input} placeholder='Category' name="category" value={formData.category} onChange={handleChange} />
                            </div>
                        </div>
                        <br />
                        <div className='row'>
                            <div className='col-lg-6'>
                                <input className="form-control" type="text" style={styles.input} placeholder="Image URL" name="image" value={formData.image} onChange={handleChange} />
                            </div>
                            <div className='col-lg-6'>
                                <input className="form-control" type="text" style={styles.input} placeholder="Rating" name="rating" value={formData.rating} onChange={handleChange} />
                            </div>
                        </div>
                        <br />
                        <div className='row'>
                            <div className='col-lg-6'>
                                <input className="form-control" type="text" style={styles.input} placeholder='Rating Count' name="ratingCount" value={formData.ratingCount} onChange={handleChange} />
                            </div>
                            <div className='col-lg-6'>
                                <input className="form-control" type="text" style={styles.input} placeholder='URL 1' name="url1" value={formData.url1} onChange={handleChange} />
                            </div>
                        </div>
                        <br />
                        <div className='row'>
                            <div className='col-lg-6'>
                                <input className="form-control" type="text" style={styles.input} placeholder='URL 2' name="url2" value={formData.url2} onChange={handleChange} />
                            </div>
                            <div className='col-lg-6'>
                                <input className="form-control" type="text" style={styles.input} placeholder='URL 3' name="url3" value={formData.url3} onChange={handleChange} />
                            </div>
                        </div>
                        <br />
                        <button className="btn btn-success rounded" type="submit">Submit</button>
                        <button className='btn' onClick={() => { addMoreData(); }}>Click bluck</button>


                        <button className='btn' onClick={() => { goToDashboard(); }}>Dashboard</button>
                    </form>
                </div>
            </div>
        </>
    )

}


export default AdminContainer;