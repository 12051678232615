import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route, } from "react-router-dom";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { history } from "./core/store"
import { httpInterceptor, setBaseUrl, setApplicationToken } from "./core/HttpService"
import _Routes from './core/Routes'


export default function RouterApp(props) {

  React.useEffect(() => {
    /* setBaseUrl(process.env.REACT_APP_BASE_URL);
    setApplicationToken(process.env.REACT_APP_TOKEN);
    httpInterceptor(); */
  }, []);


  return (
    /*  <BrowserRouter history={history}>
       <Routes history={history}>
         <Route path="/" exact element={<LoginContainer />} />
         <Route path="/home" exact element={<DashBoardContainer />} />
         <Route path="/attribute" exact element={<AttributeContainer />} />
         <Route path="/createuser" exact element={<UserContainer />} />
       </Routes>
     </BrowserRouter> */

    <_Routes history={history} />

  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));


root.render(<RouterApp />);


reportWebVitals();
