import React, { useState, useEffect } from 'react';
import Products from './Product';
import { collection, getDocs, deleteDoc, doc } from "firebase/firestore";
import { db } from '../firebase';
import AdminContainer from '../admin/AdminContainer';
import Header from './Header';
import Footer from './Footer';

function Home() {

    const [productList, setProducts] = useState([]);
    const [catList, setCategory] = useState([]);
    const [category, setSelectedCategory] = useState('products');

    const [genderFilterList, setGenderFilterList] = useState([{ display_name: "Men & Women", value: `(men|women|boy|girl|men's|mens|women's|lady)` }, { display_name: "Men", value: `(men|boy|men's)` }, { display_name: "Women", value: `(women|girl|women's|lady)` }]);
    const [selectedGender, setGender] = useState(0);

    useEffect(() => {
        if (catList.length > 0) {
            fetchPost();
        }
    }, [catList])

    const fetchPost = async () => {

        let allProducts = [];
        catList.forEach(async (element) => {

            await getDocs(collection(db, element.category_name))
                .then((querySnapshot) => {
                    const newData = querySnapshot.docs
                        .map((doc) => {
                            let test = ({ ...doc.data(), ...{ documentId: doc.id } });
                            return test;
                        });

                    allProducts = [...allProducts, ...newData]

                    console.log("all product", allProducts.length);

                })


            let shuffled = allProducts
                .map(value => ({ value, sort: Math.random() }))
                .sort((a, b) => a.sort - b.sort)
                .map(({ value }) => value)

            setProducts(shuffled);

        });



    }

    const fetchCategory = async () => {

        await getDocs(collection(db, "category"))
            .then((querySnapshot) => {
                const newData = querySnapshot.docs
                    .map((doc) => {
                        let test = ({ ...doc.data(), ...{ documentId: doc.id } });
                        console.log(">>>>", test);
                        return test;
                    });

                console.log(">>>>", newData);
                setCategory(newData);
                fetchPost();
            })

    }

    useEffect(() => {
        fetchCategory();
    }, [])


    const onDelete = async (documentId) => {
        await deleteDoc(doc(db, category, documentId));


    }

    const fetchBasedCategoryPost = async (category_name) => {

        setSelectedCategory(category_name);

        await getDocs(collection(db, category_name))
            .then((querySnapshot) => {
                const newData = querySnapshot.docs
                    .map((doc) => {
                        let test = ({ ...doc.data(), ...{ documentId: doc.id } });
                        console.log(">>>>", test);
                        return test;
                    });


                setProducts(newData);
            })

    }


    const renderProducts = () => {
        return Object.entries(productList).map(([key, value]) => {

            if (category == "clothing") {
                var pattern = "/" + genderFilterList[selectedGender].value + "/gi";
                var matches = value.element?.name?.match(pattern);
                if (matches) {
                    try {
                        value.element.url = value.element?.url?.replace("/amzn111_2", "")
                    } catch (error) {

                    }
                    return <Products id={key} singleProduct={value} onDelete={onDelete} ></Products>
                }
            }
            else {
                try {
                    value.element.url = value.element?.url?.replace("/amzn111_2", "")
                } catch (error) {

                }
                return <Products id={key} singleProduct={value} onDelete={onDelete} ></Products>
            }

        })
    }

    const renderCategory = () => {

        let test = catList.sort((a, b) => a.order - b.order)

        return Object.entries(test).map(([key, value]) => {
            if (key == 0) {
                return <a href="#" className="nav-item nav-link active" onClick={() => { fetchBasedCategoryPost(value.category_name) }}>{value.display_name}</a>
            } else {
                return <a href="#" className="nav-item nav-link" onClick={() => { fetchBasedCategoryPost(value.category_name) }}>{value.display_name}</a>
            }



        })

    }



    return (
        <>
            {/* <Header></Header> */}

            <div className="container-fluid">
                <div className="row align-items-center py-3 px-xl-5">
                    <div className="col-lg-3 d-none d-lg-block">
                        <a href className="text-decoration-none">
                            <h1 className="m-0 display-5 font-weight-semi-bold"><span className="text-primary font-weight-bold border px-3 mr-1"><i className="fas fa-shopping-cart text-primary" /></span>Spotmydeals</h1>
                        </a>
                    </div>
                    <div className="col-lg-6 col-6 text-left">
                        {/*  <form action>
                            <div className="input-group">
                                <input type="text" className="form-control" placeholder="Search for products" />
                                <button className='btn btn-success'>Get Price Histroy</button>
                            </div>
                        </form> */}
                    </div>
                    <div className="col-lg-3 col-6 text-right">
                        <a href className="btn border">
                            <i className="fas fa-heart text-primary" />
                            <span className="badge">1739</span>
                        </a>
                    </div>
                </div>
            </div>

            <div className="container-fluid">
                <div className="row border-top px-xl-5">
                    <div className="col-lg-12">
                        <nav className="navbar navbar-expand-lg bg-light navbar-light py-3 py-lg-0 px-0">
                            <a href className="text-decoration-none d-block d-lg-none">
                                <h1 className="m-0 display-5 font-weight-semi-bold"><span className="text-primary font-weight-bold border px-3 mr-1"></span>Spotmydeals</h1>
                            </a>
                            <button type="button" className="navbar-toggler" data-toggle="collapse" data-target="#navbarCollapse">
                                <span className="navbar-toggler-icon" />
                            </button>
                            <div className="collapse navbar-collapse justify-content-between" id="navbarCollapse">
                                <div className="navbar-nav mr-auto py-0">
                                    {
                                        renderCategory()
                                    }
                                    {/* <a href="#" className="nav-item nav-link active" onClick={() => { fetchPost() }}>Home</a>
                                    <a href="#" className="nav-item nav-link" onClick={() => { fetchBasedCategoryPost('clothing') }}>Clothing</a>
                                    <a href="#" className="nav-item nav-link" onClick={() => { fetchBasedCategoryPost('beauty') }} >Beauty</a>
                                    <div className="nav-item dropdown">
                                        <a href="#" className="nav-link dropdown-toggle" data-toggle="dropdown">Electronics</a>
                                        <div className="dropdown-menu rounded-0 m-0">
                                            <a href="#" className="dropdown-item" onClick={() => { fetchBasedCategoryPost('mobiles') }}>Mobile</a>
                                            <a href="#" className="dropdown-item" onClick={() => { fetchBasedCategoryPost('tv') }}>TV</a>
                                            <a href="#" className="dropdown-item" onClick={() => { fetchBasedCategoryPost('computers') }} >Laptop</a>
                                        </div>
                                    </div> */}

                                </div>
                                <div className="navbar-nav ml-auto py-0">

                                </div>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>

            {/* top banner */}
            {/*  <div className="container-fluid offer pt-5">
                <div className="row px-xl-5">
                    <div className="col-md-6 pb-4">
                        <div className="position-relative bg-secondary text-center text-md-right text-white mb-2 py-5 px-5">
                            <img src="img/offer-1.png" alt="" />
                            <div className="position-relative" style={{ zIndex: 1 }}>
                                <h5 className="text-uppercase text-primary mb-3">20% off the all order</h5>
                                <h1 className="mb-4 font-weight-semi-bold">Spring Collection</h1>
                                <a href className="btn btn-outline-primary py-md-2 px-md-3">Shop Now</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 pb-4">
                        <div className="position-relative bg-secondary text-center text-md-left text-white mb-2 py-5 px-5">
                            <img src="img/offer-2.png" alt="" />
                            <div className="position-relative" style={{ zIndex: 1 }}>
                                <h5 className="text-uppercase text-primary mb-3">20% off the all order</h5>
                                <h1 className="mb-4 font-weight-semi-bold">Winter Collection</h1>
                                <a href="https://rcm-eu.amazon-adsystem.com/e/cm?o=2&p=22&l=ur1&category=musicunlimited&banner=0XR5YAFHV8JRWQM2VJG2&f=ifr&linkID=96dd45d335a7e6b1a78fe5ea502e2ff4&t=basil111203-21&tracking_id=basil111203-21" className="btn btn-outline-primary py-md-2 px-md-3">Shop Now</a>
                            </div>
                        </div>



                    </div>
                </div>
            </div> */}
            {/* top banner ends  */}


            <div className="container-fluid pt-5">
                <div className="text-center mb-4">
                    <h2 className="section-title px-5"><span className="px-2">Deals From Amazon</span></h2>
                </div>
                <div className='row px-xl-5 pb-3'>
                    <div className='col-sm-12 text-right mr-8'>
                        <div className="btn-group">
                            <button type="button" className="btn btn-danger dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Filter {genderFilterList[selectedGender].display_name}
                            </button>
                            <div className="dropdown-menu">

                                {
                                    Object.entries(genderFilterList).map(([index, value]) => {

                                        return <a className="dropdown-item" key={index} onClick={() => { setGender(index) }}>{value.display_name}</a>

                                    })
                                }

                            </div>
                        </div>
                    </div>
                </div>
                <div className="row px-xl-5 pb-3">
                    {renderProducts()}
                </div>


            </div>




            <Footer></Footer>



        </>

    )

}

export default Home