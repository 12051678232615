import React, { useEffect, useState, useRef } from 'react';
import { Rating } from "primereact/rating";
import moment from 'moment';
import { useLocation } from 'react-router-dom';

function Products(props) {

    const [singleProduct, setSingleProduct] = useState(undefined);
    const location = useLocation();
    const [isAdmin, setIsAdmin] = useState(location.state ? location.state.isAdmin : false);
    const linkRef = useRef(null);
    const [isCopied, setIsCopied] = useState(false);

    useEffect(() => {

        if (isCopied) {
            setTimeout(() => {
                setIsCopied(false);
            }, 2000)
        }

    }, [isCopied]);


    useEffect(() => {
        setSingleProduct(props.singleProduct?.element);
    }, [props]);

    const onCardClick = (_path) => {
        linkRef.current.click();
    }


    return (

        <div className="col-lg-3 col-md-6 col-sm-12 pb-1">
            <div className="card product-item border-0 mb-4">
                <div className="card-header product-img position-relative overflow-hidden bg-transparent border p-0 product_image" >
                    <img onClick={() => { onCardClick(singleProduct?.url) }} className="w-100" src={singleProduct?.image ? singleProduct?.image : "https://st3.depositphotos.com/17828278/33150/v/450/depositphotos_331503262-stock-illustration-no-image-vector-symbol-missing.jpg"} alt="" />
                </div>
                <div className="card-body border-left border-right text-center p-1 pt-4 pb-3">
                    <div className='row'>
                        <div className='col-lg-12 col-md-12 col-sm-12'>
                            <p onClick={() => { onCardClick(singleProduct?.url) }} className='text-truncate mb-3'>{singleProduct?.name}</p>
                        </div>
                        <div className='col-lg-6 col-md-6 col-sm-6 text-left'>

                            <input type='button' className='btn btn-sm btn-dark btn-minus text-white' disabled value={singleProduct?.rating} />

                            <small className="pt-1 ml-1">({singleProduct?.rating_count})</small>
                        </div>

                        <div className='col-lg-12 col-md-12 col-sm-12'>
                            <span className='h5 text-truncate mb-3'>{singleProduct?.price.length > 20 ? <span className='text-muted'>Deal Ends</span> : singleProduct?.price} </span>
                            <span className='text-truncate ml-2 mb-3'>{singleProduct?.discount != "" ? <del>₹{singleProduct?.discount}</del> : ""}</span>
                        </div>

                        <div className='col-lg-12 col-md-12 col-sm-12'>
                            <span className='text-left mt-3 mb-1' style={{ paddingLeft: '5px', paddingRight: '5px', float: 'left', backgroundColor: "black", color: 'white' }} onClick={() => { onCardClick(singleProduct?.url) }}>Price Histroy</span>
                        </div>

                        <div className='col-lg-6 col-md-6 col-sm-6'>
                            <span className='text-truncate mb-3'>High Price : <span style={{ color: 'red' }}>{singleProduct?.highest_price == -1112 ? '0' : `₹${singleProduct?.highest_price}`}</span></span>
                        </div>
                        <div className='col-lg-6 col-md-6 col-sm-6'>
                            <span className='text-truncate mb-3'>Lowest Price : <span style={{ color: 'green' }}>{singleProduct?.lowest_price ? `₹${singleProduct?.lowest_price}` : '0'} </span></span>
                        </div>

                        {/* <div className='col-lg-12 col-md-12 col-sm-12'>
                            <span className='text-truncate mb-3 '>{moment(singleProduct?.date).fromNow()}</span>
                        </div> */}

                        <div className='col-lg-12 col-md-12 col-sm-12 mt-2 pt-2 border-top' onClick={(e) => { e.preventDefault(); navigator.clipboard.writeText(singleProduct?.url); setIsCopied(true) }}><span className='text-dark'>{isCopied ? 'Copied' : 'click to copy url'}</span></div>



                    </div>

                </div>
                <div className="card-footer d-flex justify-content-between bg-light border">
                    {isAdmin ? <a target="_blank" className="btn btn-sm btn-danger text-dark p-0" onClick={() => { props.onDelete(props.singleProduct.documentId) }}>Delete</a> : <a href={singleProduct?.url} target="_blank" className="btn btn-sm text-dark p-0"><i className="fas fa-eye text-primary mr-1" />View
                        Detail</a>}
                    <a href={singleProduct?.url} ref={linkRef} target="_blank" className="btn btn-sm text-dark p-0"><i className="fas fa-shopping-cart text-primary mr-1" />Get From Amazon</a>
                </div>
            </div>
        </div>

    )

}

export default Products;