import React, { useState } from 'react';



function Footer() {

    return (
        <>

            <div className="container-fluid bg-secondary text-dark mt-5 pt-5">
                <div className="row border-top border-light mx-xl-5 py-4">
                    <div className="col-md-6 px-xl-0">
                        <p className="mb-md-0 text-center text-md-left text-dark">
                            © <a className="text-dark font-weight-semi-bold" href="https://www.spotmydeals.com">spotmydeals</a>. All Rights Reserved. Designed
                            by
                            <a className="text-dark font-weight-semi-bold" href="#"> Basil1112</a><br />
                            Distributed By <a href="#" target="_blank"> P&KP</a>
                        </p>
                    </div>
                    <div className="col-md-6 px-xl-0 text-center text-md-right">
                        <img className="img-fluid" src="img/payments.png" alt="" />
                    </div>
                </div>
            </div>

        </>
    )
}


export default Footer;
