
export const BUILD_VERSION = "Build_Version";
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const LOGOUT = "LOGOUT";
export const START_LOADING = "START_LOADING";
export const STOP_LOADING = "STOP_LOADING";
export const SET_RUMBLE_LOCAL = "SET_RUMBLE_LOCAL";
export const SET_ASSET_LOCAL = "SET_ASSETS_LOCAL";
export const SET_COLLECTION = "SET_ACADEMY_COLLECTION"
export const SET_ASSET = "SET_ASSET_COLLECTION"
export const SET_ITEM = "SET_ITEM_COLLECTION"
export const USER_DATA = "User_Data"
export const USER_TOKEN = "User_Token"
export const APP_TOKEN = "Application_Token"
export const ACDMY_COLLECTION = "academyCollectionList";
export const ASSET_COLLECTION = "academyAssetList";
export const ITEM_COLLECTION = "academyItemList";
export const STATUS_CODES = {
    HTTP_400: 400,
    HTTP_401: 401,
    HTTP_403: 403,
    HTTP_404: 404,
    HTTP_409: 409,
    HTTP_422: 422,
    HTTP_500: 500,
    HTTP_501: 501,
}


/**
 * API and Render url defined
 */
export const RENDER_URL = {
    LOGIN_URL: '/',
    ADMIN_HOME: '/_admin_home_'

};


export const API_URL = {
    LOGIN_DASHBOARD: "/dashboard/login",
    AVATAR_LIST: "/dashboard/list",
    AVATAR_LIST_V2: "/V2/dashboard/list",
    AVATAR_FETCH_EXPAND: "/dashboard/avatar/expand",
    ADD_AVATAR: "/dashboard/avatar/add",
    ADD_AVATAR_V2: "/dashboard/avatar/addToUser",
    UPDATE_AVATAR: "/dashboard/avatar/update",
    UPDATE_USER: "/dashboard/user/update",
    ASSETS_LIST: "/dashboard/listAssets",
    CAMPAIGN_LIST: "/dashboard/campaigns/get?active=1",
    CAMPAIGN_LIST_NOT: "/dashboard/campaigns/get",
    ADD_CAMPAIGN: "/dashboard/campaigns/add",
    IMAGE_CAMPAIGN: "/dashboard/campaigns/updateImage",
    STATUS_CAMPAIGN: "/dashboard/campaigns/updateStatus",
    EXPAND_V2: "/dashboard/avatar/V2/expand",
    CREATE_NEW_URL: '/dashboard/pr/news/add',
    UPDATE_NEWS_URL: '/dashboard/pr/news/update',
    PUBLISH_NEWS_API: '/dashboard/pr/news/publish',
    GET_ALL_NEWS: '/dashboard/pr/news/all',
    GET_ALL_RELEASE: '/dashboard/pr/version/all',
    ADD_RELEASE: "/dashboard/pr/version/add",
    UPDATE_RELEASE: "/dashboard/pr/version/update",
    CAMPAIGN_STATIC_LIST: "/dashboard/campaigns/getStatistics",
    TOURNAMENT_GAME_LIST: "/dashboard/tournament/games",
    TOURNAMMENT_GAME_EXPORT: "dashboard/tournament/stats",
    TOURNAMENT_GAME_LEADERBOARD: "/dashboard/tournament/leaderboard",
    TOURNAMENT_LIST: "/dashboard/tournament/get?active=1",
    TOURNAMENT_COMPLETED_LIST: "/dashboard/tournament/get",
    TOURNAMENT_ADD: "/dashboard/tournament/add",
    TOURNAMENT_UPDATE: "/dashboard/tournament/update",

}


/**
 * 
 * @param {*} email 
 * @returns 
 */
export const validateEmail = (email) => {
    const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
};


function getColors(num) {
    const initialColor = Math.floor(Math.random() * 360);
    const increment = 360 / num;
    const hsls = [];
    for (let i = 0; i < num; i++) {
        hsls.push(Math.round((initialColor + (i * increment)) % 360));
    }
    return hsls;
}
export function generateRandomColors(_length) {
    const hsls = getColors(_length);
    let shuffled_hsls = hsls
        .map(value => ({ value, sort: Math.random() }))
        .sort((a, b) => a.sort - b.sort)
        .map(({ value }) => value)
    return shuffled_hsls;

}

